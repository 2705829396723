import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"

import PortableTitle from "components/shared/PortableTitle"
import { parseBackground, useSizing } from "lib/helpers"
import { urlForImage } from "lib/sanity.image"
import type { Logos } from "schemas"
import { Container, Grid, Stack, styled } from "styled-system/jsx"
import { token } from "styled-system/tokens"

type Props = Logos

function Component({
  title,
  body,
  logos,
  whiteBackground,

  sizingTop,
  sizingBottom,
  sizingTopMobile,
  sizingBottomMobile,
  backgroundColor1,
  backgroundColor2,
  backgroundStyle,
}: Props) {
  return (
    <styled.div
      data-block="youtubeEmbed"
      style={
        {
          background: parseBackground(
            backgroundColor1,
            backgroundColor2,
            backgroundStyle
          ),
          "--pt": useSizing(sizingTop) || "64px",
          "--pb": useSizing(sizingBottom) || "64px",
          "--ptm": useSizing(sizingTopMobile),
          "--pbm": useSizing(sizingBottomMobile),
        } as React.CSSProperties
      }
      css={{
        pt: "var(--ptm, var(--pt))",
        pb: "var(--pbm, var(--pb))",
        bgColor: "rgba(136, 156, 255, 0.29)",

        lg: {
          pt: "var(--pt)",
          pb: "var(--pb)",
        },
      }}>
      {body && (
        <Container>
          <styled.div
            css={{
              color: whiteBackground ? "primary.1" : "#F2F2F5",
              fontSize: "15px",
              fontWeight: 400,
              lineHeight: "133%",
              fontStyle: "italic",
              maxW: "637px",
              mx: "auto",
              mb: "16px",

              md: {
                mb: "28px",
                fontSize: "23px",
              },
            }}>
            <PortableTitle
              as="div"
              altColor={token("colors.primary.4")}
              css={{
                fontSize: "14px",
                lineHeight: "133%",
                textAlign: "center",

                md: {
                  fontSize: "23px",
                },
              }}>
              {body}
            </PortableTitle>
          </styled.div>
        </Container>
      )}

      {logos && logos.length > 0 && (
        <Container
          css={{
            mdDown: {
              display: "contents",
            },
          }}>
          <Grid
            columns={{ lg: 2 }}
            gap={4}
            gridTemplateColumns="1fr 4fr"
            placeItems="center"
            css={{
              color: "#000",
              py: 3,
              px: 6,
              background: "#fff",

              md: {
                rounded: 30,
                py: 5,
                px: 10,
              },
            }}>
            <styled.div css={{ justifySelf: "start" }}>
              <styled.p
                css={{
                  color: "primary.4",
                  fontSize: "8px",
                  textWrap: "nowrap",
                  textTransform: "uppercase",

                  lg: {
                    fontSize: "16px",
                  },
                }}>
                {title}
              </styled.p>
            </styled.div>
            <Stack
              direction="row"
              gap={4}
              align="center"
              justify="space-between"
              css={{ width: "100%" }}>
              {Array.isArray(logos) &&
                logos.map((logo, i) => (
                  <styled.a
                    key={logo.url || i}
                    href={logo.url}
                    css={{
                      display: "block",
                      width: "max-content",

                      "&:last-of-type": {
                        pr: 6,
                      },

                      mdDown: {
                        "&:nth-child(n+4)": {
                          display: "none",
                        },
                      },
                    }}>
                    <styled.img
                      key={urlForImage(logo.image).url()}
                      alt={logo.image.alt}
                      src={urlForImage(logo.image).url()}
                      style={
                        {
                          "--height": `${logo.height || 40}px`,
                          "--height-m": `${(logo.height || 40) / 2}px`,
                        } as React.CSSProperties
                      }
                      css={{
                        height: "var(--height-m, 20px)",

                        lg: {
                          height: "var(--height, 40px)",
                        },
                      }}
                    />
                  </styled.a>
                ))}
            </Stack>
          </Grid>
        </Container>
      )}
    </styled.div>
  )
}

export default Component
export type { Props as YoutubeEmbedProps }
